import englishMessages from 'ra-language-english';
import { raFormLayoutLanguageEnglish } from '@react-admin/ra-form-layout';

const messages = {
    ...englishMessages,
    ...raFormLayoutLanguageEnglish,
    kuggar: {
        auth: {
            or: 'OR',
            sign_in_with_kuggar: 'Sign in with Kuggar',
            sign_in_with_microsoft: 'Sign in with Microsoft',
            sign_in_with_google: 'Sign in with Google',
            sign_in_with_okta: 'Sign in with Okta',
            go_back_to_login: 'Click here to go back to the login page.',
            invalid_credentials: 'Invalid credentials. Please try again.',
            invalid_state_token: 'Invalid anti-forgery state token.',
            validating_microsoft_account: 'Validating your Microsoft account, please wait...',
            validating_google_account: 'Validating your Google account, please wait...',
            validating_okta_account: 'Validating your Okta account, please wait...'
        },
        menu: {
            caches: 'Caches',
            countries: 'Countries',
            currencies: 'Currencies',
            exchange_rates: 'Exchange Rates',
            logs: 'Logs',
            onboarding: 'Onboarding',
            organisations: 'Organisations',
            routing: 'Routing',
            roles: 'Roles',
            rules: 'Rules',
            users: 'Users',
            virtual_cards: 'Virtual Cards',
            virtual_cards_expiry: 'Cards Near Expiry',
            issuer_accounts: 'Issuer Accounts',
            user_defined_data: 'Enhanced Data Maps',
            vc_access_profiles: 'Access Profiles',
            encryption_keys: 'Encryption Keys',
            issuer_credentials: 'Issuer Credentials',
            issuer_environments: 'Issuer Environments',
            issuer_ftps: 'Issuer FTPs',
            prepaid_accounts: 'Pre-Funded Accounts',
            card_activity: 'Card Activity',
            funding_account_activity: 'Funding Account Activity',
            non_zero_card_balance: 'Non-Zero Card Balances',
            notifications: 'Notifications',
            home: 'Home'
        },
        dashboard: {
            title: 'Welcome, %{username} to the Customer Portal'
        },
        logs: {
            data: 'Data',
            exception: 'Exception',
            level: 'Level',
            linked_virtual_card: 'Linked Virtual Card',
            log: 'Log',
            message: 'Message',
            timestamp: 'Timestamp (UTC)',
            type: 'Type'
        },
        country: {
            alpha_2_code: 'Alpha 2 Code',
            alpha_3_code: 'Alpha 3 Code',
            country_name: 'Country Name'
        },
        currency: {
            alpha_code: 'Alpha Code',
            minor_units: 'Minor Units',
            rate: 'Rate',
        },
        role: {
            level: 'Level',
            scope: 'Scope',
            scopes: 'Authorised Scopes'
        },
        organisation: {
            delete_organisation: 'Delete Organisation',
            on_delete_error_notify: 'Error deleting organisation',
            on_delete_success_notify: 'Organisation deleted successfully',
            on_success_notify: 'Organisation created',
            organisation: 'Organisation',
            organisation_name: 'Organisation Name',
            organisation_ref: 'Organisation Ref',
            reference_code_validation: 'Code must be unique per customer',
            update_organisation: 'Update Organisation'
        },
        onboarding: {
            access_profile_name: 'Organisation Access Profile Name',
            access_profile_name_validation: 'Access profile name required',
            api_key: 'API Key (Nium)',
            api_key_validation: 'API key required',
            api_password: 'API Password (Nium)',
            api_password_validation: 'Nium password required',
            authentication_domain: 'Authentication Domain',
            create_title: 'Onboard New Organisation',
            currency_code: 'Currency Code',
            customer_access_profile: 'Customer Access Profile',
            default_cache_currency: 'Default Cache Currency',
            default_cache_currency_validation: 'Default cache currency required',
            email_address: 'Organisation Email Address',
            encryption_key: 'Encryption Key',
            issuer_account_values: 'Issuer Accounts',
            issuer_account: 'Account Reference',
            issuer_environment: 'Issuer Environment',
            issuer_ftp_directories: 'Issuer FTP Directories',
            issuer_ftp_host: 'Issuer FTP Host',
            issuer_ftp_password: 'Issuer FTP Password',
            issuer_ftp_port: 'Issuer FTP Port',
            issuer_ftp_username: 'Issuer FTP Username',
            list_title: 'Welcome to the Organisation Onboarding',
            list_subtitle: 'Click the button below to get started.',
            role: 'Role',
            user_values: 'Users',
            user_defined_data_values: 'Fields',
            user_defined_data_key: 'Label',
            user_defined_data_type: 'Type',
            user_defined_data_value: 'Default Value',
            virtual_card_factory_name: 'Virtual Card Factory Name',
            virtual_card_factory_name_validation: 'Virtual card factory name required',
        },
        prepaid_accounts: {
            prepaid_account: 'Pre-Funded Account',
            available_balance: 'Available Balance',
            actual_balance: 'Actual Balance',
            scheduled_loads: 'Scheduled Loads',
            scheduled_load_amount: 'Amount',
            scheduled_load_failure_reason: 'Failure Reason',
            scheduled_load_schedule_date: 'Schedule Date (UTC)',
            scheduled_load_clearance_date: 'Clearance Date (UTC)'
        },
        user: {
            delete_user: 'Delete User',
            on_delete_error_notify: 'Error deleting user',
            on_delete_success_notify: 'User deleted successfully',
            on_success_notify: 'User created',
            roles: 'Roles',
            update_user: 'Update User',
            username: 'Username',
            password: 'Password',
            current_password: 'Current Password',
            new_password: 'New Password',
            virtualCardsAccessProfile: 'Payments Access Profile',
            authentication_method: 'User Directory',
            import: {
                import: 'Import',
                on_success_notify: 'Users created',
            }
        },
        virtual_cards: {
            access_profile: 'Access Profile',
            available_balance: 'Available Balance',
            cancel_card: 'Cancel/Close Card',
            cancel_card_confirmation: 'Are you sure you want to cancel/close the Virtual Card with %{hostName} ID %{id}? This action cannot be undone.',
            cancel_card_multi: 'Cancel/Close Selected Cards',
            cancel_card_multi_confirmation: 'Are you sure you want to cancel/close the selected Virtual Cards? This action cannot be undone.',
            cancel_card_multi_error: 'An error occurred when attempting to cancel/close multiple Virtual Cards. Please review and try again.',
            cancelled_card_data: 'Cancelled Card Data',
            card_limit: 'Card Limit',
            card_limit_create: 'Card Limit',
            card_limit_update: 'Card Limit',
            card_limit_validation: 'Card Limit must be greater than zero',
            card_limit_helper: 'In the units of currency selected above',
            card_number: 'Card Number',
            card_validity: 'Card Validity',
            create_method: 'Method',
            created_card_data: 'Created Card Data',
            currency_code: 'Currency Code',
            currency_codes: 'Currency Codes',
            failure_reason: 'Failure Reason',
            generation_date: 'Generation Date',
            last_modified: 'Last Modified (UTC)',
            last_refreshed: 'Last Refreshed',
            last_updated_card_data: 'Last Updated Card Data',
            limit: 'Limit',
            on_delete_error_notify: 'Error deleting virtual card',
            on_delete_success_notify: 'Virtual card deleted successfully',
            on_success_notify: 'Virtual card created, update sent to',
            provider: 'Provider',
            provider_id: 'Provider ID',
            provider_id_eNett: 'vNett Transaction ID',
            provider_id_mastercard: 'Purchase Request ID',
            provider_id_mint: 'Card Reference',
            provider_id_wex: 'Purchase Log Unique ID',
            refresh: 'Refresh Card',
            source_cache: 'Source Cache',
            source_cache_helper: 'Select a cache to override the routing rules',
            state: 'State',
            system_card_id: 'System Card ID',
            tab_summary: 'Summary',
            tab_details: 'Details',
            tab_logs: 'Logs',
            tab_history: 'History',
            title_create: 'Create New Virtual Card',
            title_update: 'Update Virtual Card',
            update_card: 'Update Card',
            user_defined_fields: 'Enhanced Data Fields',
            valid_from: 'Active From',
            valid_to: 'Active To',
            valid_to_validation: 'Active From must be earlier than Active To',
            valid_to_max_validation: 'Active To must not be more than a year or more of Active From',
            virtual_card: 'Virtual Card'
        },
        caches: {
            cache: 'Cache',
            create_request: 'Create Request',
            count: 'Count',
            current_cache: 'Current Cache',
            delete_cache: 'Delete Cache',
            empty_cache: 'Empty Cache',
            failure_reason: 'Failure Reason',
            fill_cache: 'Fill Cache',            
            lower_limit: 'Lower Limit',
            lower_limit_validation: 'Lower limit must be less than upper limit',
            new_cache: 'New Cache',
            on_delete_error_notify: 'Error deleting cache',
            on_delete_success_notify: 'Cache deleted successfully',
            on_success_notify: 'Cache created',
            refresh_cache:'Refresh cache',
            route_currencies: 'Route Currencies',
            set_default: 'Set as default',
            switch: 'Switch Cache',
            title_create: 'Create New Cache',
            title_update: 'Update Cache',
            update_cache: 'Update Cache',
            upper_limit: 'Upper Limit',
            dynamic_create_request: 'Dynamic Create Request',
            tab_create_request: 'Card Create Request',
            valid_from_today: 'Active From Today',
            days_valid_from_current_date: 'Days Active From Current Date',
            force_unique_reference: 'Force Unique Reference',
            funding_currency_code: 'Funding Currency Code'
        },
        issuer_accounts: {
            account_defined_data: 'Account Defined Data',
            airPlus_account_number: 'Account Number',
            buffer: 'Buffer',
            buffer_type: 'Buffer Type',
            eNett_country_code: 'Country Code',
            eNett_issued_to_ecn: 'Issued To ECN',
            eNett_requester_ecn: 'Requester ECN',
            eNett_username: 'Username',
            mastercard_company_id: 'Company ID',
            mastercard_data_source_id: 'Data Source ID',
            mastercard_rcn_alias: 'RCN Alias',
            mastercard_rcn_id: 'RCN ID',
            mastercard_supplier_id: 'Supplier ID',
            mastercard_template_id: 'Template ID',
            mint_funding_account_reference: 'Funding Account Reference',
            on_success_notify: 'Issuer Account created',
            title_create: 'Create Issuer Account',
            title_update: 'Update Issuer Account',
            update_issuer_account: 'Update Issuer Account',
            wex_bank_number: 'Bank Number',
            wex_company_number: 'Company Number',
            wex_pool_name: 'Pool Name',
            kuggar_card_issuer_template_id: 'Card Issuer Template ID',
            kuggar_card_processor_policy_id: 'Card Processor Policy ID'
        },
        issuer_credentials: {
            update_issuer_credentials: 'Update Issuer Credentials',
            title_update: 'Update Issuer Credentials',
            on_success_notify: 'Issuer Credentials created',
            issuer_environment_id: 'Issuer Environment ID',
            eNett_client_access_key: 'Client Access Key',
            eNett_integrator_code: 'Integrator Code',
            eNett_integrator_access_key: 'Integrator Access Key',
            airPlus_username: 'Username',
            airPlus_password: 'Password',
            masterCard_consumer_key: 'Consumer Key',
            masterCard_pkcs12base64: 'PKCS12 Base 64',
            masterCard_signing_key_password: 'Signing Key Password',
            mint_api_key: 'API Key',
            mint_password: 'Password',
            wex_org_group_login_id: 'Org Group Login ID',
            wex_username: 'Username',
            wex_password: 'Password',
            title_create: 'Create Issuer Credentials',
            kuggar_auth_url: 'Authorisation URL',
            kuggar_client_id: 'Client ID',
            kuggar_client_secret: 'Client Secret'
        },
        eNett_create_request: {
            activation_date: 'Activation Date',
            expiry_date: 'Expiry Date',
            minimum_authorisation_amount: 'Minimum Authorisation Amount',
            maximum_authorisation_amount: 'Maximum Authorisation Amount',
            currency_code: 'Currency Code',
            funding_currency_code: 'Funding Currency Code',
            integrator_reference: 'Integrator Reference',
            is_multi_use: 'Is Multi Use',
            merchant_category_name: 'Merchant Category Name',
            notes: 'Notes',
            user_references: 'User References'
        },
        air_plus_flight_create_request: {
            passenger_name: 'Passenger Name',
            flight_date: 'Flight Date',
            departure_airport_code: 'Departure Airport Code',
            destination_airport_code: 'Destination Airport Code',
            airline_code: 'Airline Code',
            flight_class: 'Flight Class'
        },
        air_plus_hotel_create_request: {
            guest_name: 'Guest Name',
            check_in_date: 'Check In Date',
            check_out_date: 'Check Out Date',
            room_nights: 'Room Nights'
        },
        air_plus_procurement_create_request: {
            procurement_type: 'Procurement Type',
            name: 'Name',
            free_text: 'Free Text'
        },
        air_plus_create_request: {
            valid_from_date: 'Valid From Date',
            valid_to_date: 'Valid To Date',
            limit: 'Limit',
            limit_currency_numeric_code: 'Limit Currency Numeric Code',
            transaction_currency_numeric_codes: 'Transaction Currency Numeric Codes',
            merchant_name: 'Merchant Name',
            merchant_country_numeric_code: 'Merchant Country Numeric Code',
            generating_user_name: 'Generating User Name',
            generating_user_mail: 'Generating User Mail',
            generating_user_id: 'Generating User ID',
            generating_user_phone: 'Generating User Phone',
            card_user_name: 'Card User Name',
            card_user_mail: 'Card User Mail',
            card_user_id: 'Card User ID',
            card_user_phone: 'Card User Phone',
            dbi_accounting_unit: 'DBI Accounting Unit',
            dbi_accounting_code: 'DBI Accounting Code',
            dbi_order_number: 'DBI Order Number',
            dbi_departure_date: 'DBI Departure Date',
            dbi_department_code: 'DBI Department Code',
            dbi_internal_account: 'DBI Internal Account',
            dbi_cost_centre: 'DBI Cost Centre',
            dbi_employee_number: 'DBI Employee Number',
            dbi_project_number: 'DBI Project Number',
            dbi_destination: 'DBI Destination'
        },
        wex_create_request: {
            amount: 'Amount',
            active_from_date: 'Active From Date',
            active_to_date: 'Active To Date',
            billing_currency: 'Billing Currency',
            local_currency: 'Local Currency',
            min_amount: 'Minimum Amount',
            auth_hold_days: 'Authorisation Hold Days',
            user_defined_fields: 'User Defined Fields'
        },
        mastercard_create_request: {
            valid_from_date: 'Valid From Date',
            valid_to_date: 'Valid To Date',
            minimum_amount: 'Minimum Amount',
            maximum_amount: 'Maximum Amount',
            maximum_transactions: 'Maximum Transactions',
            currency_numeric_code: 'Currency Numeric Code',
            is_merchant_currency: 'Is Merchant Currency',
            notify_supplier: 'Notify Supplier',
            supplier_emails: 'Supplier Emails',
            country_codes: 'Country Codes',
            strict_pre_auth_check: 'Strict Pre Auth Check',
            authorisation_hold_days: 'Authorisation Hold Days',
            velocity_period: 'Velocity Period',
            curfew_days: 'Curfew - Days of Week',
            curfew_from_time: 'Curfew - From Time UTC',
            curfew_to_time: 'Curfew - To Time UTC',
            times_of_day: 'Times Of Day',
            times_of_day_day: 'Times Of Day - Day',
            times_of_day_from_time: 'Times Of Day - From Time UTC',
            times_of_day_to_time: 'Times Of Day - To Time UTC',
            custom_fields: 'Custom Fields'
        },
        mint_create_request: {
            virtual_card_factory_name: 'Virtual Card Factory Name',
            currency: 'Currency',
            funding_account_reference: 'Funding Account Reference',
            external_call_reference: 'External Call Reference',
            card_name: 'Card Name',
            amount: 'Amount',
            external_identifier: 'External Identifier',
            card_user: 'Card User',
            authentication_data: 'Authentication Data',
            clearance_date: 'Clearance Date',
            card_info: 'Card Info'
        },
        issuer_environment: {
            update_issuer_environment: 'Update Issuer Environment',
            service_url: 'Service URL',
            title_update: 'Update Issuer Environment',
            on_success_notify: 'Issuer Environment created',
        },
        issuer_ftp: {
            update_issuer_ftp: 'Update Issuer FTP',
            title_create: 'Create Issuer FTP',
            title_update: 'Update Issuer FTP',
            on_success_notify: 'Issuer FTP created',
            ftp_hostname: 'FTP Hostnamne',
            host: 'FTP Hostname',
            port: 'FTP Port',
            username: 'FTP Username',
            password: 'FTP Password',
            private_key: 'SSH Private Key',
            private_key_helper: 'Optional. Leave blank if not connecting to SFTP with a Private Key.',
            directories: 'FTP Directories',
            directory: 'Directory'
        },
        user_defined_data: {
            issuer_account_label: 'Issuer Account Label',
            on_success_notify: 'Enhanced Data Map created',
            title_create: 'Create Enhanced Data Map',
            type: 'Type',
            update_user_defined_data: 'Update Enhanced Data Map',
            user_defined_data_validation: 'Issuer Account Labels must be unique',
            user_label: 'User Label',
            account_defined_data_id: 'Account Defined Data ID',
            label: 'Label'
        },
        vc_access_profiles: {
            credential_id: 'Credential ID',
            encryption_key_id: 'Encryption key ID',
            service_account: 'Service Account',
            title_create: 'Create Access Profile',
            update_access_profile: 'Update Access Profile',
            on_success_notify: 'Access Profile created',
            type: 'Type'
        },
        vc_authorisations: {
            authorisations: 'Authorisations',
            merchant_name: 'Merchant Name',
            adjustment_type: 'Adjustment Type',
            billing_amount: 'Billing Amount',
            transaction_amount: 'Transaction Amount',
            available_balance: 'Available Balance',
            terminal_country_code: 'Terminal Country',
            auth_code: 'Auth Code',
            response_code: 'Response Code',
            response_description: 'Response Description'
        },
        vc_transactions: {
            transactions: 'Transactions',
            credited: 'Funds Credited',
            debited: 'Funds Debited',
            balance: 'Balance',
            fee: 'Transaction Fee',
            forexCurrency: 'Forex Currency',
            forexRate: 'Forex Rate',
            forexFee: 'Forex Fee',
            forexAmount: 'ForexAmount'
        },
        encryption_keys: {
            update_encryption_key: 'Update Encryption Key',
            azure_key_vault_key_reference: 'Key Reference',
            aes256_key_reference: 'Key Reference',
            title_create: 'Create Encryption Key',
            title_update: 'Update Encryption Key',
            on_success_notify : 'Encryption Key created'
        },
        notifications: {
            content: 'Content',
            content_html: 'Content HTML',
            delete_notification: 'Delete Notification',
            from: 'From',
            medium: 'Medium',
            on_delete_error_notify: 'Error deleting notification',
            on_delete_success_notify: 'Notification deleted successfully',
            period: 'Period',
            periodStart: 'Period Start',
            recipient: 'Recipient',
            recipients: 'Recipients',
            subject: 'Subject',
            update_notification: 'Update Notification',
            title_create: 'Create Notification',
            title_update: 'Update Notification',
            toggle_html_code_view: 'Toggle HTML/Code View'
        },
        rules: {
            rules: 'Rules',
            rule_number: 'Order',
            filter_query: 'Filter',
            data_transform_query: 'Data Transform',
            cache: 'Route To Cache',
            array_element_paths: 'Parse Options - Input Array Elements',
            on_success_notify: 'Rules created',
            data_sample: 'Data Sample',
            open_ai_message: 'Open AI Message',       
            open_ai_in_progress_text: 'Generating with Open AI, please wait...', 
            open_ai_button_title: 'Generate with Open AI',
            create_button_title: 'Create with Open AI'
        },
        shared: {
            acquirer_reference_number: 'Acquirer Reference Number',
            active: 'Active',
            adjustment_id: 'Adjustment ID',
            as_at_date: 'As At Date',
            auth_code: 'Auth Code',
            auth_transaction_date: 'Auth Transaction Date',
            available_balance: 'Available Balance',
            balance_before: 'Balance Before',
            balance_after: 'Balance After',
            balance_adjustment: 'Balance Adjustment',
            bin_owner: 'Bin Owner',
            blocked_balance: 'Blocked Balances',
            card_currency: 'Card Currency',
            card_external_id: 'Card External ID',
            card_factory_name: 'Card Factory Name',
            card_reference: 'Card Reference',
            card_name: 'Card Name',
            card_num: 'Card Number',
            card_number: 'Card Number',
            card_scheme: 'Card Scheme',
            client: 'Client',
            community: 'Community',
            country: 'Country',
            credential: 'Credential',
            currency: 'Currency',
            currencies: 'Currencies',
            custom_fields: 'Custom Fields',
            custom_field_number: 'Field No.',
            date_issued: 'Date Issued',
            default: 'Default',
            description: 'Description',     
            destination_details: 'Destination Details',
            destination_type: 'Destination Type',
            direction: 'Direction',
            ecb_rate: 'ECB Rate',
            email_address: 'Email Address',
            email_address_validation: 'Must be a valid email address',
            encryption_key: 'Encryption key',
            external_id: 'External ID',
            external_ref: 'External Reference',
            exchange_rate: 'Exchange Rate',
            file_name: 'File Name',
            financial_balance: 'Financial Balance',
            forex_fee: 'Forex Fee',
            forex_flag: 'Forex Flag',
            funding_account_name: 'Account Name',
            id: 'ID',
            interchange_amount: 'Interchange Amount',
            interchange_currency: 'Interchange Currency',
            issuer_account: 'Issuer Account',
            issuer_credentials: 'Issuer Credentials',
            issuer_credential: 'Issuer Credential',
            issuer_environment: 'Issuer Environment',
            kuggar_id: '%{HostName} ID',
            label: 'Label',
            loaded_amount: 'Loaded Amount',
            merchant_category_code: 'Merchant Category Code',
            merchant_country: 'Merchant Country',
            merchant_name: 'Merchant Name',
            name: 'Name',
            non_forex_fee: 'Non Forex Fee',
            numeric_code: 'Numeric Code',
            on_copy_notify: 'Copied to clipboard',
            organisation: 'Organisation',
            organisation_id: 'Organisation ID',
            original_amount: 'Original Amount',
            original_currency: 'Original Currency',
            participant_amount: 'Participant Amount',
            participant_currency: 'Participant Currency',
            participant_details: 'Participant Details',
            participant_sink: 'Participant Sink',
            participan_type: 'Participant Type',
            provider: 'Provider',
            provider_date: 'Provider Date',
            reference_code: 'Reference Code',
            refund_amount: 'Refund Amount',
            region: 'Region',
            settled_amount: 'Settled Amount',
            start_date: 'Start Date',
            state: 'State',
            status: 'Status',
            scheme_code: 'Scheme Code',
            source_details: 'Source Details',
            source_type: 'Source Type',
            tab_summary: 'Summary',
            timestamp: 'Timestamp (UTC)',
            transaction_amount: 'Transaction Amount',
            transaction_author: 'Transaction Author',
            transaction_currency: 'Transaction Currency',
            transaction_date: 'Transaction Date',
            transaction_id: 'Transaction ID',
            transaction_info: 'Transaction Info',
            transaction_type: 'Transaction Type',
            time_till_expiry: 'Time Till Expiry',
            type: 'Type',
            unloaded_amount: 'Unloaded Amount',
            update: 'Update',
            value: 'Value'
        }
    }
};

export default messages;