import * as React from "react";
import { Admin, Resource, useTranslate } from 'react-admin';
import { QueryClient } from 'react-query';

import Layout from './Layout';
import Dashboard from './Dashboard';
import LoginForm from './LoginForm'
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import i18nProvider from './i18nProvider';
import { Version, Environment } from "./configProvider";
import { HasViewPermission } from './shared';

import { LogList, LogShow } from './views/logs';
import { CountryList } from './views/countries';
import { CurrencyList } from './views/currencies';
import { RoleList } from './views/roles';
import { OrganisationList, OrganisationShow, OrganisationEdit, OrganisationCreate } from './views/organisations';
import {UserList, UserShow, UserEdit, UserCreate, ImportUsers} from './views/users';
import { VirtualCardList, VirtualCardShow, VirtualCardEdit, VirtualCardCreate } from './views/virtualCards';
import { CacheList, CacheShow, CacheEdit, CacheCreate } from './views/caches';
import {RoutingList, RoutingEdit, CurrencyRoutingEdit} from './views/routing';
import { PrepaidAccountList, PrepaidAccountShow } from './views/prepaidAccounts';
import { IssuerAccountsList, IssuerAccountShow, IssuerAccountEdit, IssuerAccountCreate } from './views/issuerAccounts';
import { UserDefinedDataList, UserDefinedDataShow, UserDefinedDataEdit, UserDefinedDataCreate } from './views/userDefinedData';
import { VcAccessProfilesList, VcAccessProfilesShow, VcAccessProfilesEdit, VcAccessProfilesCreate } from './views/vcAccessProfiles';
import { EncryptionKeyList, EncryptionKeyShow, EncryptionKeyEdit, EncryptionKeyCreate } from './views/encryptionKeys';
import { IssuerCredentialsList, IssuerCredentialsShow, IssuerCredentialsEdit, IssuerCredentialsCreate } from './views/issuerCredentials';
import { IssuerEnvironmentsList, IssuerEnvironmentCreate, IssuerEnvironmentEdit, IssuerEnvironmentShow } from './views/issuerEnvironments';
import { IssuerFtpsList, IssuerFtpCreate, IssuerFtpShow, IssuerFtpEdit } from "./views/issuerFtps";
import { NotificationList, NotificationCreate, NotificationShow, NotificationEdit } from "./views/notifications";
import {
    CardActivityList,
    CardActivityShow,
    FundingAccountActivityList,
    FundingAccountActivityShow,
    NonZeroCardBalanceList,
    NonZeroCardBalanceShow,
    VirtualCardExpiryList
} from './views/reports';
import { ExchangeRatesList } from "./views/exchangeRates";
import { OnboardingCreate, OnboardingList } from "./views/onboarding";
import { RuleList, RulesCreate } from './views/rules';


import LogIcon from '@mui/icons-material/Subject';
import CountryIcon from '@mui/icons-material/Public';
import CurrencyIcon from '@mui/icons-material/AttachMoney';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RoleIcon from '@mui/icons-material/AdminPanelSettings';
import OrganisationIcon from '@mui/icons-material/Business';
import UserIcon from '@mui/icons-material/People';
import OnboardingIcon from '@mui/icons-material/AddBusiness';
import VirtualCardIcon from '@mui/icons-material/CreditCard';
import CacheIcon from '@mui/icons-material/AccountBalanceWallet';
import RoutingIcon from '@mui/icons-material/CallSplit';
import PrepaidAccountIcon from '@mui/icons-material/PriceChange';
import IssuerAccountIcon from '@mui/icons-material/Build';
import UserDefinedDataIcon from '@mui/icons-material/SyncAlt';
import VcAccessProfileIcon from '@mui/icons-material/AccountBox';
import EncryptionKeyIcon from '@mui/icons-material/Key';
import IssuerCredentialsIcon from '@mui/icons-material/Password';
import IssuerEnvironmentIcon from '@mui/icons-material/Api';
import IssuerFtpIcon from '@mui/icons-material/CloudDownload';
import CardActivityIcon from '@mui/icons-material/Payments';
import FundingAccountActivityIcon from '@mui/icons-material/CurrencyExchange';
import NonZeroCardBalanceIcon from '@mui/icons-material/Money';
import NotificationIcon from '@mui/icons-material/Notifications';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {CustomRoutes} from 'react-admin';
import { Route } from "react-router-dom";
import EditIssuerAccountDefinedData from "./components/EditIssuerAccountDefinedData";
import RuleIcon from '@mui/icons-material/Rule';

const isProd = Environment.Name.toLowerCase().startsWith('prod');
const isKuggar = Environment.HostName === 'Kuggar';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

const GetResources = (translate) => [
    <Resource
        name="credential"
        options={{ api: 'Platform', roles: ['Administrator','Contributor','Reader']}} />,
    <Resource
        name="logs"
        list={LogList}
        show={LogShow}
        icon={LogIcon}
        options={{ api: 'Platform', roles: ['35d2804e-84c9-4374-a830-8443efaaf926','ea700a03-2eec-494f-8e33-f073642cebb0','7f1d1a38-f898-4c2c-8765-0badb557f387','66bf5b46-7fcf-49d7-8d2f-fb06fa240bed'], category: 'Admin', label: translate('kuggar.menu.logs') }}
    />,
    <Resource
        name="codes/country"
        list={CountryList}
        icon={CountryIcon}
        options={{ api: 'Platform', roles: ['Administrator','Contributor','RulesManager','VirtualCardUser','Reader'], category: 'Codes', label: translate('kuggar.menu.countries') }}
    />,
    <Resource
        name="codes/currency"
        list={CurrencyList}
        icon={CurrencyIcon}
        options={{ api: 'Platform', roles: ['Administrator','Contributor','RulesManager','VirtualCardUser','Reader'], category: 'Codes', label: translate('kuggar.menu.currencies') }}
    />,
    <Resource
        name="currency-exchange"
        list={ExchangeRatesList}
        icon={CurrencyExchangeIcon}
        options={{ api: 'Platform', roles: ['Administrator','Contributor','RulesManager','VirtualCardUser','Reader'], category: 'Codes', label: translate('kuggar.menu.exchange_rates') }}
    />,
    <Resource
        name="role"
        list={RoleList}
        icon={RoleIcon}
        options={{ api: 'Platform', roles: ['Administrator','Contributor','Reader'], category: 'Codes', label: translate('kuggar.menu.roles') }}
    />,
    <Resource
        name="onboarding"
        list={OnboardingList}
        create={OnboardingCreate}
        icon={OnboardingIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','Reader'], category: 'Customer Management', label: translate('kuggar.menu.onboarding') }}
    />,
    <Resource
        name="organisation"
        list={OrganisationList}
        show={OrganisationShow}
        edit={OrganisationEdit}
        create={OrganisationCreate}
        icon={OrganisationIcon}
        options={{ api: 'Platform', roles: ['Administrator','Contributor','Reader'], category: 'Customer Management', label: translate('kuggar.menu.organisations') }}
    />,
    <Resource
        name="user"
        list={UserList}
        show={UserShow}
        edit={UserEdit}
        create={UserCreate}
        icon={UserIcon}
        options={{ api: 'Platform', roles: ['Administrator','Contributor','Reader'], category: 'Customer Management', label: translate('kuggar.menu.users') }}
    />,
    <Resource
        name="encryption_key"
        list={EncryptionKeyList}
        show={EncryptionKeyShow}
        edit={EncryptionKeyEdit}
        create={EncryptionKeyCreate}
        icon={EncryptionKeyIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','Reader'], category: 'Customer Management', label: translate('kuggar.menu.encryption_keys') }}
    />,
    <Resource
        name="notification"
        list={NotificationList}
        show={NotificationShow}
        edit={NotificationEdit}
        create={NotificationCreate}
        icon={NotificationIcon}
        options={{ api: 'Platform', roles: ['Administrator','Contributor','Reader'], category: 'Customer Management', label: translate('kuggar.menu.notifications') }}
    />,
    <Resource
        name="virtual_card"
        list={VirtualCardList}
        show={VirtualCardShow}
        edit={VirtualCardEdit}
        create={VirtualCardCreate}
        icon={VirtualCardIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','VirtualCardUser','Reader'], category: 'Payments', label: translate('kuggar.menu.virtual_cards') }}
    />,
    <Resource
        name="cache"
        list={CacheList}
        show={CacheShow}
        icon={CacheIcon}
        edit={CacheEdit}
        create={CacheCreate}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','VirtualCardUser','Reader'], category: 'Payments', label: translate('kuggar.menu.caches') }}
    />,
    <Resource
        name="routing"
        list={RoutingList}
        edit={RoutingEdit}
        icon={RoutingIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','VirtualCardUser','Reader'], route: 'cache_route', category: 'Payments', label: translate('kuggar.menu.routing') }}
    >
        <Route path="/:id/currency" element={<CurrencyRoutingEdit />} />
    </Resource>,
    <Resource
        name="rules"
        list={RuleList}
        create={RulesCreate}
        icon={RuleIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'cache_route', category: 'Payments', label: translate('kuggar.menu.rules') }}
    />,
    <Resource
        name="balance_instrument"
        list={PrepaidAccountList}
        show={PrepaidAccountShow}
        icon={PrepaidAccountIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','VirtualCardUser','Reader'], route: 'prepaid_account', category: 'Payments', label: translate('kuggar.menu.prepaid_accounts') }}
    />,
    <Resource
        name="card_activity"
        list={CardActivityList}
        show={CardActivityShow}
        icon={CardActivityIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager', 'VirtualCardUser', 'Reader'], route: 'report_data/card_activity', category: 'Reports', label: translate('kuggar.menu.card_activity') }}
    />,
    <Resource
        name="cards_near_expiry"
        list={VirtualCardExpiryList}
        icon={AccessTimeIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','VirtualCardUser','Reader'], route: 'virtual_card', category: 'Reports', label: translate('kuggar.menu.virtual_cards_expiry') }}
    />,
    <Resource
        name="funding_account_activity"
        list={FundingAccountActivityList}
        show={FundingAccountActivityShow}
        icon={FundingAccountActivityIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager', 'Reader'], route: 'report_data/funding_account_activity', category: 'Reports', label: translate('kuggar.menu.funding_account_activity') }}
    />,
    <Resource
        name="non_zero_card_balance"
        list={NonZeroCardBalanceList}
        show={NonZeroCardBalanceShow}
        icon={NonZeroCardBalanceIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager', 'Reader'], route: 'report_data/non_zero_card_balance', category: 'Reports', label: translate('kuggar.menu.non_zero_card_balance') }}
    />,
    <Resource
        name="vc_access_profile"
        list={VcAccessProfilesList}
        show={VcAccessProfilesShow}
        edit={VcAccessProfilesEdit}
        create={VcAccessProfilesCreate}
        icon={VcAccessProfileIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'access_profile', category: 'Payments Setup', label: translate('kuggar.menu.vc_access_profiles') }}
    />,
    <Resource
        name="user_defined_data"
        list={UserDefinedDataList}
        show={UserDefinedDataShow}
        edit={UserDefinedDataEdit}
        create={UserDefinedDataCreate}
        icon={UserDefinedDataIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'user_defined_datamap', category: 'Payments Setup', label: translate('kuggar.menu.user_defined_data') }}
    />,
    <Resource
        name="issuer_account"
        list={IssuerAccountsList}
        show={IssuerAccountShow}
        icon={IssuerAccountIcon}
        edit={IssuerAccountEdit}
        create={IssuerAccountCreate}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','Reader'], category: 'Payments Setup', label: translate('kuggar.menu.issuer_accounts') }}
    />,
    <Resource
        name="issuer_credentials"
        list={IssuerCredentialsList}
        show={IssuerCredentialsShow}
        edit={IssuerCredentialsEdit}
        create={IssuerCredentialsCreate}
        icon={IssuerCredentialsIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'issuer_credential', category: 'Payments Setup', label: translate('kuggar.menu.issuer_credentials') }}
    />,
    <Resource
        name="issuer_environment"
        list={IssuerEnvironmentsList}
        show={IssuerEnvironmentShow}
        edit={IssuerEnvironmentEdit}
        create={IssuerEnvironmentCreate}
        icon={IssuerEnvironmentIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','Reader'], category: 'Payments Setup', label: translate('kuggar.menu.issuer_environments') }}
    />,
    <Resource
        name="issuer_ftp"
        list={IssuerFtpsList}
        show={IssuerFtpShow}
        edit={IssuerFtpEdit}
        create={IssuerFtpCreate}
        icon={IssuerFtpIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','Reader'], category: 'Payments Setup', label: translate('kuggar.menu.issuer_ftps') }}
    />
];

const Footer = () => {
    const [headers, setHeaders] = React.useState(null);
    React.useEffect(() => {
        async function getHeaders() {
            const headers = await fetch(`${Environment.API.Kuggar}/ping`).then(rsp => rsp.headers);
            setHeaders(headers);
        }
        getHeaders();
    }, []);

    let cloudProvider = 'Local';
    let cloudRegion = 'Local';
    if (headers) {
        if (headers.has('x-cloud-provider')) {
            cloudProvider = headers.get('x-cloud-provider');
            localStorage.setItem('cloud-provider', cloudProvider);
        }
        if (headers.has('x-cloud-region')) {
            cloudRegion = headers.get('x-cloud-region');
            localStorage.setItem('cloud-region', cloudRegion);
        }
    }

    return (
        <>
            <div className="spacer">&nbsp;</div>
            <div className="footer">v{Version} | {cloudProvider} ({cloudRegion}) | <a href={`mailto:${Environment.SupportEmail}`}>{Environment.SupportEmail}</a></div>
        </>
    );
};

const App = () => {
    const translate = useTranslate();
    const knownResources = GetResources(translate);
    
    const fetchResources = () => {
        // TODO remove the hard-coded reference to rule filtering once ready
        const secureResources = knownResources.filter(x => (x.props.name === 'rules' && isKuggar) || (x.props.name !== 'rules' && HasViewPermission(x.props.options.api, `/${x.props.options.route ? x.props.options.route : x.props.name}/`)));
        return Promise.resolve(secureResources);
    };

    React.useEffect(() => {
        document.title = isProd
            ? `${Environment.HostName} Customer Portal`
            : `[${Environment.Name.replace(`-${Environment.HostName}`, '')}] ${Environment.HostName} Customer Portal`;

        if (!isKuggar) {
            const theme = Environment.HostName.toLowerCase();
            document.documentElement.classList.add(`${theme}-theme`);
            document.getElementById("favIcon").href = document.getElementById("favIcon").href.replace("favicon.ico", `favicon-${theme}.ico`);
            document.getElementById("appleTouchIcon").href = document.getElementById("appleTouchIcon").href.replace("logo192.jpg", `logo192-${theme}.jpg`);
        }
    });

    return (
        <>
            <Admin
                layout={Layout}
                loginPage={LoginForm}
                dashboard={Dashboard}
                queryClient={queryClient}
                i18nProvider={i18nProvider}
                dataProvider={dataProvider}
                authProvider={authProvider}
                requireAuth
            >
                <CustomRoutes >
                    <Route exact path="/issuer_account/:id/defined_data" element={<EditIssuerAccountDefinedData/>} />
                    <Route path='/user/import' element={<ImportUsers/>} />
                </CustomRoutes>
                {fetchResources}
            </Admin>
            <Footer />
        </>
    );
};

export default App;